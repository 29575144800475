.User-popup__header {
  padding: 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #c0c0c0; }
  .User-popup__header-title {
    margin: 0; }

.User-popup__body-primary {
  text-align: center; }
  .User-popup__body-primary:after {
    display: block;
    content: '';
    width: 85%;
    height: 1px;
    margin: 0 auto;
    border-bottom: 1px solid #c0c0c0; }

.User-popup__body-additional {
  padding: 5% 0 15% 5%; }

.User-popup__picture {
  margin-top: 25px; }
  .User-popup__picture-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden; }
  .User-popup__picture-svg {
    width: 85px;
    height: 85px;
    fill: #3f4346; }

.User-popup__name {
  margin: 10px 0 0; }

.User-popup__phone-number {
  margin-top: 5px;
  color: #98d5ad; }

.User-popup__group {
  display: grid;
  grid-template-areas: 'label value';
  grid-column-gap: 20px;
  grid-template-columns: 100px;
  grid-template-rows: 40px;
  align-items: center; }

.User-popup__label {
  grid-area: label;
  text-align: right;
  font-weight: 700; }

.User-popup__value {
  grid-area: value;
  color: #c0c0c0;
  font-size: 14px; }

.User-popup__btn {
  position: absolute;
  bottom: 10px;
  left: 20px; }
  .User-popup__btn-delete {
    padding: 8px 20px;
    color: #fff;
    background: #f00;
    border: 1px solid #dc0000;
    transition: all .4s;
    outline: none; }
    .User-popup__btn-delete:hover {
      box-shadow: 0 0 8px 0 #dc0000; }
