.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff; }
  .Loading__item {
    position: relative;
    width: 160px;
    height: 10px;
    background: #E5E8E8;
    overflow: hidden; }
  .Loading__item span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #3f4346;
    font: 0/0 auto;
    -webkit-animation: load 1.6s ease infinite;
            animation: load 1.6s ease infinite; }

@-webkit-keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }

@keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff; }
  .Loading__item {
    position: relative;
    width: 160px;
    height: 10px;
    background: #E5E8E8;
    overflow: hidden; }
  .Loading__item span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #3f4346;
    font: 0/0 auto;
    -webkit-animation: load 1.6s ease infinite;
            animation: load 1.6s ease infinite; }

@-webkit-keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }

@keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }

.Logo {
  background: #3f4346; }
  .Logo__btn {
    background: transparent;
    border: 0;
    outline: none; }
    .Logo__btn-icon {
      max-width: 160px;
      height: 36px;
      padding: 10px;
      fill: #fff; }

.Header-content {
  display: grid;
  grid-template-areas: 'title title' 'search addPersonBtn';
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid #ebebeb; }
  .Header-content__label {
    grid-area: title;
    margin: 0 0 15px 18px; }
  .Header-content__search {
    grid-area: search;
    position: relative; }
    .Header-content__search-input {
      padding: 5px 25px;
      border: 1px solid #c0c0c0; }
    .Header-content__search-svg {
      position: absolute;
      top: 3px;
      left: 5px;
      width: 18px;
      height: 18px;
      fill: #c0c0c0; }
  .Header-content__btn {
    grid-area: addPersonBtn; }
    .Header-content__btn-add-person {
      padding: 5px 15px;
      color: #fff;
      background: #08a742;
      border: 1px solid #08a742;
      -webkit-transition: all .4s;
      transition: all .4s;
      outline: none; }
      .Header-content__btn-add-person:hover {
        box-shadow: 0 0 8px 0 #08a742; }
  @media (min-width: 720px) {
    .Header-content {
      grid-template-areas: 'title title title title search addPersonBtn'; }
      .Header-content__label {
        margin-bottom: 0; } }

.Users__list {
  padding: 0 10px;
  list-style: none; }
  .Users__list-item {
    margin-bottom: 15px; }
    .Users__list-item:last-child {
      margin-bottom: 0; }
    .Users__list-item.dragged {
      position: fixed;
      max-width: 1082px;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #fff;
      box-shadow: 0 0 40px 0 #000; }
  .Users__list .placeholder {
    position: relative;
    height: 45px;
    background: #404346; }
    .Users__list .placeholder:before {
      content: "Drop here";
      position: absolute;
      top: 10px;
      left: 30px;
      color: #fff; }

.User {
  display: grid;
  grid-template-areas: 'title profilePicture' 'organization profilePicture';
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #ebebeb;
  cursor: pointer;
  -webkit-transition: all .4s;
  transition: all .4s; }
  .User:hover {
    box-shadow: 0 0 25px 0 #bdbdbd; }
  .User__title {
    grid-area: title;
    margin: 0 0 10px;
    font-size: 17px; }
  .User__organization {
    grid-area: organization; }
    .User__organization-icon {
      width: 16px;
      height: 16px;
      fill: #3f4346;
      vertical-align: bottom; }
    .User__organization-name {
      margin-left: 5px;
      font-size: 14px; }
  .User__picture {
    grid-area: profilePicture; }
    .User__picture-img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden; }
    .User__picture-svg {
      width: 45px;
      height: 45px;
      fill: #3f4346; }

.Pagination {
  position: relative;
  text-align: center;
  padding: 20px 10px;
  border-top: 1px solid #ebebeb; }
  .Pagination__btn {
    font: 0/0 auto;
    border: 0;
    background: transparent;
    cursor: pointer;
    outline: none; }
    .Pagination__btn-svg {
      width: 20px;
      height: 20px;
      stroke: #08a742;
      stroke-width: 2px; }
      .Pagination__btn-svg:hover {
        -webkit-animation: arrow .8s ease infinite;
                animation: arrow .8s ease infinite; }
    .Pagination__btn:disabled {
      cursor: default; }
      .Pagination__btn:disabled .Pagination__btn-svg {
        stroke: #3f4346; }
        .Pagination__btn:disabled .Pagination__btn-svg:hover {
          -webkit-animation: none;
                  animation: none; }
  .Pagination__span {
    padding: 0 5px;
    color: #08a742;
    font-size: 25px;
    line-height: 25px;
    font-weight: 700; }

@-webkit-keyframes arrow {
  from {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); } }

@keyframes arrow {
  from {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  to {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); } }

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff; }
  .Loading__item {
    position: relative;
    width: 160px;
    height: 10px;
    background: #E5E8E8;
    overflow: hidden; }
  .Loading__item span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #3f4346;
    font: 0/0 auto;
    -webkit-animation: load 1.6s ease infinite;
            animation: load 1.6s ease infinite; }

@-webkit-keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }

@keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }

.Popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 50px);
  max-width: 450px;
  background: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 0 40px 0 #3f4346;
  overflow: scroll; }
  .Popup__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 67, 70, 0.8);
    font: 0/0 auto; }
  .Popup__btn {
    padding: 10px 20px;
    text-align: right;
    background: #f7f7f7;
    border-top: 1px solid #c0c0c0; }
    .Popup__btn-close {
      padding: 8px 20px;
      background: #fff;
      border: 1px solid #c0c0c0;
      -webkit-transition: all .4s;
      transition: all .4s;
      outline: none; }
      .Popup__btn-close:hover {
        box-shadow: 0 0 8px 0 #c0c0c0; }
      .Popup__btn-close--icon {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 16px;
        width: 16px;
        fill: #404346;
        font: 0/0 auto;
        background-color: transparent;
        border: none;
        outline: none; }
        .Popup__btn-close--icon:hover {
          fill: #c0c0c0;
          -webkit-transform: scale(1.3);
                  transform: scale(1.3); }
  @media (min-width: 480px) {
    .Popup {
      overflow: hidden; } }

.Header__logo {
  background: #3f4346; }
  .Header__logo-icon {
    max-width: 160px;
    height: 36px;
    padding: 10px;
    fill: #fff; }

.Header__content-label {
  margin: 0;
  padding: 20px 10px;
  border-bottom: 1px solid #ebebeb; }

.User-popup__header {
  padding: 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #c0c0c0; }
  .User-popup__header-title {
    margin: 0; }

.User-popup__body-primary {
  text-align: center; }
  .User-popup__body-primary:after {
    display: block;
    content: '';
    width: 85%;
    height: 1px;
    margin: 0 auto;
    border-bottom: 1px solid #c0c0c0; }

.User-popup__body-additional {
  padding: 5% 0 15% 5%; }

.User-popup__picture {
  margin-top: 25px; }
  .User-popup__picture-img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden; }
  .User-popup__picture-svg {
    width: 85px;
    height: 85px;
    fill: #3f4346; }

.User-popup__name {
  margin: 10px 0 0; }

.User-popup__phone-number {
  margin-top: 5px;
  color: #98d5ad; }

.User-popup__group {
  display: grid;
  grid-template-areas: 'label value';
  grid-column-gap: 20px;
  grid-template-columns: 100px;
  grid-template-rows: 40px;
  align-items: center; }

.User-popup__label {
  grid-area: label;
  text-align: right;
  font-weight: 700; }

.User-popup__value {
  grid-area: value;
  color: #c0c0c0;
  font-size: 14px; }

.User-popup__btn {
  position: absolute;
  bottom: 10px;
  left: 20px; }
  .User-popup__btn-delete {
    padding: 8px 20px;
    color: #fff;
    background: #f00;
    border: 1px solid #dc0000;
    -webkit-transition: all .4s;
    transition: all .4s;
    outline: none; }
    .User-popup__btn-delete:hover {
      box-shadow: 0 0 8px 0 #dc0000; }

.Add-user-popup__header {
  padding: 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #c0c0c0; }
  .Add-user-popup__header--success {
    color: #fff;
    background: #08a742; }
  .Add-user-popup__header-title {
    margin: 0; }

.Add-user-popup__body {
  padding: 20px 20px 40px; }

.Add-user-popup__label {
  display: block;
  padding: 20px 5px 5px; }

.Add-user-popup__input {
  width: 95%;
  padding: 5px;
  border: 1px solid #c0c0c0; }

.Add-user-popup__btn {
  position: absolute;
  bottom: 10px;
  left: 20px; }
  .Add-user-popup__btn-add-user {
    padding: 8px 20px;
    color: #fff;
    background: #08a742;
    border: 1px solid #08a742;
    -webkit-transition: all .4s;
    transition: all .4s;
    outline: none; }
    .Add-user-popup__btn-add-user:hover {
      box-shadow: 0 0 8px 0 #08a742; }
    .Add-user-popup__btn-add-user:disabled {
      cursor: default;
      color: #3f4346;
      background: #fff;
      border-color: #c0c0c0; }
      .Add-user-popup__btn-add-user:disabled:hover {
        box-shadow: none; }

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Regular.ttf") format("truetype"); }

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #3f4346; }

button {
  cursor: pointer; }

.container {
  position: relative;
  max-width: 1082px;
  margin: 0 auto; }

