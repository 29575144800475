.User {
  display: grid;
  grid-template-areas: 'title profilePicture' 'organization profilePicture';
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #ebebeb;
  cursor: pointer;
  transition: all .4s; }
  .User:hover {
    box-shadow: 0 0 25px 0 #bdbdbd; }
  .User__title {
    grid-area: title;
    margin: 0 0 10px;
    font-size: 17px; }
  .User__organization {
    grid-area: organization; }
    .User__organization-icon {
      width: 16px;
      height: 16px;
      fill: #3f4346;
      vertical-align: bottom; }
    .User__organization-name {
      margin-left: 5px;
      font-size: 14px; }
  .User__picture {
    grid-area: profilePicture; }
    .User__picture-img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden; }
    .User__picture-svg {
      width: 45px;
      height: 45px;
      fill: #3f4346; }
