.Logo {
  background: #3f4346; }
  .Logo__btn {
    background: transparent;
    border: 0;
    outline: none; }
    .Logo__btn-icon {
      max-width: 160px;
      height: 36px;
      padding: 10px;
      fill: #fff; }
