.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff; }
  .Loading__item {
    position: relative;
    width: 160px;
    height: 10px;
    background: #E5E8E8;
    overflow: hidden; }
  .Loading__item span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #3f4346;
    font: 0/0 auto;
    animation: load 1.6s ease infinite; }

@keyframes load {
  from {
    left: -100%; }
  to {
    left: 100%; } }
