.Popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 50px);
  max-width: 450px;
  background: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 40px 0 #3f4346;
  overflow: scroll; }
  .Popup__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 67, 70, 0.8);
    font: 0/0 auto; }
  .Popup__btn {
    padding: 10px 20px;
    text-align: right;
    background: #f7f7f7;
    border-top: 1px solid #c0c0c0; }
    .Popup__btn-close {
      padding: 8px 20px;
      background: #fff;
      border: 1px solid #c0c0c0;
      transition: all .4s;
      outline: none; }
      .Popup__btn-close:hover {
        box-shadow: 0 0 8px 0 #c0c0c0; }
      .Popup__btn-close--icon {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 16px;
        width: 16px;
        fill: #404346;
        font: 0/0 auto;
        background-color: transparent;
        border: none;
        outline: none; }
        .Popup__btn-close--icon:hover {
          fill: #c0c0c0;
          transform: scale(1.3); }
  @media (min-width: 480px) {
    .Popup {
      overflow: hidden; } }
