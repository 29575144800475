@font-face {
  font-family: "Open Sans";
  src: url("/fonts/OpenSans-Regular.ttf") format("truetype"); }

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #3f4346; }

button {
  cursor: pointer; }

.container {
  position: relative;
  max-width: 1082px;
  margin: 0 auto; }
