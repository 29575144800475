.Users__list {
  padding: 0 10px;
  list-style: none; }
  .Users__list-item {
    margin-bottom: 15px; }
    .Users__list-item:last-child {
      margin-bottom: 0; }
    .Users__list-item.dragged {
      position: fixed;
      max-width: 1082px;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #fff;
      box-shadow: 0 0 40px 0 #000; }
  .Users__list .placeholder {
    position: relative;
    height: 45px;
    background: #404346; }
    .Users__list .placeholder:before {
      content: "Drop here";
      position: absolute;
      top: 10px;
      left: 30px;
      color: #fff; }
