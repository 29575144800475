.Header__logo {
  background: #3f4346; }
  .Header__logo-icon {
    max-width: 160px;
    height: 36px;
    padding: 10px;
    fill: #fff; }

.Header__content-label {
  margin: 0;
  padding: 20px 10px;
  border-bottom: 1px solid #ebebeb; }
