.Header-content {
  display: grid;
  grid-template-areas: 'title title' 'search addPersonBtn';
  justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid #ebebeb; }
  .Header-content__label {
    grid-area: title;
    margin: 0 0 15px 18px; }
  .Header-content__search {
    grid-area: search;
    position: relative; }
    .Header-content__search-input {
      padding: 5px 25px;
      border: 1px solid #c0c0c0; }
    .Header-content__search-svg {
      position: absolute;
      top: 3px;
      left: 5px;
      width: 18px;
      height: 18px;
      fill: #c0c0c0; }
  .Header-content__btn {
    grid-area: addPersonBtn; }
    .Header-content__btn-add-person {
      padding: 5px 15px;
      color: #fff;
      background: #08a742;
      border: 1px solid #08a742;
      transition: all .4s;
      outline: none; }
      .Header-content__btn-add-person:hover {
        box-shadow: 0 0 8px 0 #08a742; }
  @media (min-width: 720px) {
    .Header-content {
      grid-template-areas: 'title title title title search addPersonBtn'; }
      .Header-content__label {
        margin-bottom: 0; } }
