.Pagination {
  position: relative;
  text-align: center;
  padding: 20px 10px;
  border-top: 1px solid #ebebeb; }
  .Pagination__btn {
    font: 0/0 auto;
    border: 0;
    background: transparent;
    cursor: pointer;
    outline: none; }
    .Pagination__btn-svg {
      width: 20px;
      height: 20px;
      stroke: #08a742;
      stroke-width: 2px; }
      .Pagination__btn-svg:hover {
        animation: arrow .8s ease infinite; }
    .Pagination__btn:disabled {
      cursor: default; }
      .Pagination__btn:disabled .Pagination__btn-svg {
        stroke: #3f4346; }
        .Pagination__btn:disabled .Pagination__btn-svg:hover {
          animation: none; }
  .Pagination__span {
    padding: 0 5px;
    color: #08a742;
    font-size: 25px;
    line-height: 25px;
    font-weight: 700; }

@keyframes arrow {
  from {
    transform: scale(1.1); }
  to {
    transform: scale(1.3); } }
