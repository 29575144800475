.Add-user-popup__header {
  padding: 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #c0c0c0; }
  .Add-user-popup__header--success {
    color: #fff;
    background: #08a742; }
  .Add-user-popup__header-title {
    margin: 0; }

.Add-user-popup__body {
  padding: 20px 20px 40px; }

.Add-user-popup__label {
  display: block;
  padding: 20px 5px 5px; }

.Add-user-popup__input {
  width: 95%;
  padding: 5px;
  border: 1px solid #c0c0c0; }

.Add-user-popup__btn {
  position: absolute;
  bottom: 10px;
  left: 20px; }
  .Add-user-popup__btn-add-user {
    padding: 8px 20px;
    color: #fff;
    background: #08a742;
    border: 1px solid #08a742;
    transition: all .4s;
    outline: none; }
    .Add-user-popup__btn-add-user:hover {
      box-shadow: 0 0 8px 0 #08a742; }
    .Add-user-popup__btn-add-user:disabled {
      cursor: default;
      color: #3f4346;
      background: #fff;
      border-color: #c0c0c0; }
      .Add-user-popup__btn-add-user:disabled:hover {
        box-shadow: none; }
